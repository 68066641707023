import { mode, Styles } from '@chakra-ui/theme-tools'

const styles: Styles = {
  global: (props) => ({
    body: {
      fontFamily: 'body',
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('white', 'gray.800')(props),
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      lineHeight: 'base'
    },
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props)
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word'
    },
    '.daypicker input': {
      bg: 'white',
      p: 2,
      borderWidth: '1px',
      borderColor: 'gray.200',
      borderRadius: 'md'
    },
    '.daypicker input:focus': {
      outline: 'none',
      borderWidth: '2px',
      borderColor: 'blue.500',
      borderRadius: 'md'
    }
  })
}

export default styles
