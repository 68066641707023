const baseStyle = {
  fontSize: 'sm',
  marginEnd: 3,
  mb: 2,
  fontWeight: 'medium',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  opacity: 1,
  _disabled: {
    opacity: 0.4
  }
}

export default {
  baseStyle
}
