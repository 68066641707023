const baseStyle = {
  w: '100%',
  mx: 'auto',
  maxW: '60ch',
  px: '1rem'
}

export default {
  baseStyle
}
