import App, { AppContext, AppProps } from 'next/app'
import React, { createContext } from 'react'
import '@fontsource/inter'
import { ChakraProvider } from '@chakra-ui/react'
import { Layout } from '@/helpers/layouts'
import { MeProvider } from '@/contexts/me'
import { theme } from 'src/helpers/theme/index'
import { UserProvider as Auth0UserProvider, getSession } from '@auth0/nextjs-auth0'

import { DialogProvider } from '../components/dialogProvider/index'
import { GraphQLProvider } from '@/contexts/GraphQLProvider'
import { PharmaciesProvider } from '@/contexts/PharmacyProvider'
import { LocaleProvider } from '@/components'
import './globals.css'
import { PollingProvider } from '@/contexts/notificationPolling/NotificationPolling'

interface MyAppProps extends AppProps {
  token: string
}

export const TokenContext = createContext<{ token: string }>({
  token: null
})

function MyApp({ Component, pageProps, token, router }: MyAppProps) {
  return (
    <Auth0UserProvider>
      <TokenContext.Provider value={{ token: token }}>
        <GraphQLProvider pageProps={pageProps} token={token}>
          <LocaleProvider>
            <ChakraProvider theme={theme}>
              <DialogProvider>
                {['/signout', '/signin', '/'].includes(router.route) ? (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                ) : (
                  <MeProvider>
                    <PollingProvider>
                      <PharmaciesProvider>
                        <Layout>
                          <Component {...pageProps} />
                        </Layout>
                      </PharmaciesProvider>
                    </PollingProvider>
                  </MeProvider>
                )}
              </DialogProvider>
            </ChakraProvider>
          </LocaleProvider>
        </GraphQLProvider>
      </TokenContext.Provider>
    </Auth0UserProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  let token = null
  if (['/', '/en', '/fr'].includes(appContext.ctx.req?.url)) {
    return {
      redirect: {
        permanent: false,
        destination: '/signin'
      },
      ...appProps
    }
  }

  if (!['/signin', '/', '/500', '/en/500', '/fr/500'].includes(appContext.ctx.req?.url)) {
    if (!appContext.ctx.req || !appContext.ctx.res) {
      console.error('No request or responses')
    } else {
      const session = await getSession(appContext.ctx.req, appContext.ctx.res)

      token = session?.idToken
    }
  }

  return {
    ...appProps,
    token
  }
}

export default MyApp
