import React from 'react'
import { BsArrowReturnRight } from 'react-icons/bs'
import { HiUsers } from 'react-icons/hi'
import { IoMdListBox } from 'react-icons/io'
import { MdChatBubble, MdLocalShipping, MdShoppingBasket, MdViewAgenda, MdMedication, MdOutlineSupportAgent } from 'react-icons/md'
import { RiChatFollowUpFill } from 'react-icons/ri'
import { FaStethoscope, FaHospital } from 'react-icons/fa'
import { IoDocumentTextSharp } from 'react-icons/io5'

type Roles = 'user' | 'super-admin'

export const NavItems = (role: Roles) => {
  const navs = {
    'super-admin': [
      // {
      //   title: 'Clients',
      //   path: '/super-admin/clients',
      //   icon: <MdPeople />
      // },
      {
        title: 'allUsers',
        path: '/super-admin/users',
        icon: <HiUsers />
      },
      {
        title: 'Establishments',
        path: '/super-admin/establishments',
        icon: <FaHospital />
      },
      {
        title: 'Drugs',
        path: '/super-admin/drugs',
        icon: <MdMedication />
      },
      {
        title: 'Doctors',
        path: '/super-admin/doctors',
        icon: <FaStethoscope />
      },
      {
        title: 'adminSupport',
        useTitle: true,
        icon: MdOutlineSupportAgent,
        children: [
          {
            title: 'taskCategories',
            path: '/super-admin/task-categories'
          },
          {
            title: 'predefinedMessages',
            path: '/super-admin/predefined-messages'
          },
          {
            title: 'empegoLinks',
            path: '/super-admin/empego-links'
          }
        ]
      }
    ],
    user: [
      {
        title: 'Tasks',
        path: '/tasks',
        icon: <IoMdListBox />
      },
      {
        title: 'Users',
        path: '/users',
        icon: <HiUsers />
      },
      // {
      //   title: 'Drugs',
      //   path: '/drugs',
      //   icon: <MdLocalPharmacy />
      // },
      {
        title: 'Messages',
        path: '/messages',
        icon: <MdChatBubble />
      },
      {
        title: 'Orders',
        path: '/orders',
        icon: <MdShoppingBasket />
      },
      {
        title: 'Followups',
        path: '/followups',
        icon: <RiChatFollowUpFill />
      },
      {
        title: 'documents',
        path: '/documents',
        icon: <IoDocumentTextSharp />
      },
      {
        title: 'More',
        children: [
          {
            title: 'Agenda',
            path: '/agenda',
            icon: <MdViewAgenda />
          },
          {
            title: 'Shipping',
            path: '/shipping',
            icon: <MdLocalShipping />
          },
          {
            title: 'Empego',
            path: '/empego',
            icon: <BsArrowReturnRight />
          }
        ]
      }
    ],
    admin: [
      {
        title: 'Tasks',
        path: '/tasks',
        icon: <IoMdListBox />
      },
      {
        title: 'Users',
        path: '/users',
        icon: <HiUsers />
      },
      // {
      //   title: 'Drugs',
      //   path: '/drugs',
      //   icon: <MdLocalPharmacy />
      // },
      {
        title: 'Messages',
        path: '/messages',
        icon: <MdChatBubble />
      },
      {
        title: 'Orders',
        path: '/orders',
        icon: <MdShoppingBasket />
      },
      {
        title: 'Followups',
        path: '/followups',
        icon: <RiChatFollowUpFill />
      },
      {
        title: 'documents',
        path: '/documents',
        icon: <IoDocumentTextSharp />
      },
      {
        title: 'More',
        children: [
          {
            title: 'Agenda',
            path: '/agenda',
            icon: <MdViewAgenda />
          },
          {
            title: 'Shipping',
            path: '/shipping',
            icon: <MdLocalShipping />
          },
          {
            title: 'Empego',
            path: '/empego',
            icon: <BsArrowReturnRight />
          }
        ]
      }
    ]
  }

  return navs?.[role] ?? []
}
