import React, { createContext, useContext, useEffect, useState } from 'react'
import { showNotification } from '@/utils/notificationUtils'
import { useGetAssignedTasks } from '@/hooks/useGetAssignedTasks'
import moment from 'moment'
import { MeContext } from '@/contexts/me/meContext'
import { useGetMessagesFromUsers } from '@/hooks/useGetMessagesFromUsers'
import { useTranslation } from '@/utils/i18n'

const PollingContext = createContext({})

export const PollingProvider = ({ children }) => {
  const { me } = useContext(MeContext)
  const [fetchTime, setFetchTime] = useState(moment().subtract(15, 'seconds'))
  const { data: tasks } = useGetAssignedTasks({ assignedTo: me.oAuthId, createdAt: fetchTime.toISOString() })
  const { data: messages } = useGetMessagesFromUsers({ createdAt: fetchTime.toISOString() })
  const t = useTranslation()

  if (tasks?.getAssignedTasks.length > 0) {
    showNotification(t('notifications.taskTitle'), t('notifications.taskBody'), `${window.location.origin}/tasks`)
  }

  if (messages?.getMessagesFromUsers.length > 0) {
    showNotification(t('notifications.messageTitle'), t('notifications.messageBody'), `${window.location.origin}/messages`)
  }

  useEffect(() => {
    const timer = setInterval(() => setFetchTime(moment().subtract(15, 'seconds')), 15000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <PollingContext.Provider value={{}}>
      {children}
    </PollingContext.Provider>
  )
}
