import React, { useContext } from 'react'
import { Box, Container, VStack } from '@chakra-ui/react'
import { Topbar } from './topbar'
import { useUser } from '@auth0/nextjs-auth0'
import { PharmaciesContext } from '@/contexts/PharmacyProvider'
import { Loader } from '@/components'
import { useRouter } from 'next/router'
import { useValidateUserAccess } from '@/hooks'

export interface ILayoutProps {
  children: any
}

export const Layout = ({ children }: ILayoutProps) => {
  const { user } = useUser()
  const router = useRouter()
  const { selectedPharmacy } = useContext(PharmaciesContext)

  const { userLoading, userNotAllowed } = useValidateUserAccess()

  const loadingBox = (
    <Box position="fixed" top={0} right={0} left={0} bottom={0} justifyContent="center" alignItems="center" display="flex" bg="white">
      <Loader fullWidth />
    </Box>
  )

  if (userNotAllowed || userLoading) return loadingBox

  if (!user && ['/signin', '/en/signin', '/fr/signin', '/signout', '/', '/en', '/fr'].includes(router.pathname)) {
    return (
      <>
        <Box position="fixed" top={0} right={0} left={0} bottom={0} justifyContent="center" alignItems="center" display="flex" bg="white">
          <Loader fullWidth />
        </Box>
        {children}
      </>
    )
  }

  if (user && selectedPharmacy) {
    return (
      <VStack>
        <Topbar />
        <Box width="full" pt="70px">
          <Container maxW="container.xl">
            <Box>{children}</Box>
          </Container>
        </Box>
      </VStack>
    )
  }

  return loadingBox
}
