
export const showNotification = (title, body, redirectUrl) => {
  if (typeof Notification !== 'undefined') {
    if (Notification.permission === 'granted') {
      const notif = new Notification(title, {
        body: body,
        icon: '/airix.svg'
      })
      notif.onclick = () => {
        window.open(redirectUrl, 'blank')
      }
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          showNotification(title, body, redirectUrl)
        }
      })
    }
  }
}
