import React from 'react'
import { AirixLogo } from '../logo'
import { Box, Text, Button, Flex, HStack, Link, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { NavItems } from '../navItems'
import { ProfileMenu } from './profileMenu'
import { useRouter } from 'next/router'
import { useTranslation } from '@/utils/i18n'
import { MdMoreHoriz } from 'react-icons/md'
import { useUser } from '@auth0/nextjs-auth0'

export interface ITopbarProps { }

export const Topbar = () => {
  const router = useRouter()
  const t = useTranslation()
  const { user } = useUser()
  const isSuperAdminRoute = router.asPath.startsWith('/super-admin')

  const role = isSuperAdminRoute ? 'super-admin' : user?.['https://airix.co/roles']?.[0]

  return (
    <Box
      width="full"
      position="fixed"
      top={0}
      zIndex={100}
      borderBottomWidth={1}
      bg="gray.50"
      // height="full"
      left={0}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box height="60px" display="flex" px={4} alignItems="center" justifyContent="center">
        <Link href={'/tasks'} zIndex={101}>
          <HStack spacing={2} bg="white" borderWidth={1} borderRadius="md" p={2}>
            <AirixLogo />
            <Text fontWeight="bold" color="gray.700">
              La suite Airix
            </Text>
          </HStack>
        </Link>
      </Box>
      <Box display="flex" w="full" position="absolute" justifyContent="center">
        {NavItems(role).map((item) =>
          !item.children ? (
            <Box py={2} key={item.title}>
              <Button
                mx={role === 'user' ? 2 : 5}
                px={role === 'super-admin' ? 10 : 5}
                aria-label={item.title}
                leftIcon={item?.icon}
                bg={router.pathname.startsWith(item.path) ? (role === 'user' ? 'blackAlpha.200' : 'gray.1000') : 'transparent'}
                color={router.pathname.startsWith(item.path) ? (role === 'user' ? 'gray.600' : 'blackAlpha.500') : 'gray.400'}
                _hover={{
                  bg: role === 'user' ? 'blackAlpha.200' : 'gray.1000',
                  borderRadius: isSuperAdminRoute ? 0 : 4,
                  color: router.pathname.startsWith(item.path) && isSuperAdminRoute ? 'blackAlpha.500' : null
                }}
                onClick={() => {
                  if (item.title === 'Followups') {
                    window.open('https://www.libreview.com/', '_blank')
                  } else {
                    router.push(item.path)
                  }
                }}
                size="md"
                borderRadius={isSuperAdminRoute ? 0 : 4}
              >
                {t(`common.${item.title}` || item.title)}
              </Button>
            </Box>
          ) : (
            <Menu placement="bottom-end" key={item.title}>
              {item.useTitle ? (
                <MenuButton
                  mx={role === 'user' ? 2 : 5}
                  my={2}
                  px={5}
                  py={2}
                  fontWeight="600"
                  color="gray.400"
                  _hover={{
                    bg: role === 'user' ? 'blackAlpha.200' : 'gray.1000',
                    borderRadius: isSuperAdminRoute ? 0 : 4,
                    color: router.pathname.startsWith(item.path) && isSuperAdminRoute ? 'blackAlpha.500' : null
                  }}
                >
                  <Flex alignItems="center" gridGap={2}>
                    <item.icon />
                    <Text>{t(`common.${item.title}`)}</Text>
                  </Flex>
                </MenuButton>
              ) : (
                <MenuButton as={IconButton} aria-label="more" mx={2} my={2} bg="transparent" icon={<MdMoreHoriz />} color="gray.500" _hover={{ bg: 'gray.100' }} fontSize="2xl" />
              )}
              <MenuList>
                {item.children?.map((childItem) => (
                  <MenuItem
                    key={childItem.title}
                    color="gray.700"
                    icon={childItem.icon}
                    fontSize="md"
                    onClick={() => {
                      router.push(childItem.path)
                    }}
                  >
                    {t(`common.${childItem.title}` || childItem.title)}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )
        )}
      </Box>
      <Box p={3} position="fixed" right={0} top={0} display="flex" zIndex={120} justifyContent="end" alignItems="center">
        <ProfileMenu />
      </Box>
    </Box>
  )
}
