import { gql, useQuery } from '@apollo/client'

const MESSAGES_FROM_USERS = gql`
  query($createdAt: String) {
    getMessagesFromUsers(createdAt: $createdAt) {
      messageId
      oAuthId
      messageText
      createdAt
    }
  }
`
export interface IUseGetMessagesFromUsersProps {
  createdAt: string
}

export const useGetMessagesFromUsers = ({ createdAt }: IUseGetMessagesFromUsersProps) => {
  return useQuery(MESSAGES_FROM_USERS, {
    variables: {
      createdAt
    }
  })
}
