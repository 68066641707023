import { gql, useQuery } from '@apollo/client'

const GET_ASSIGNED_TASKS = gql`
  query($assignedTo: String!, $createdAt: String) {
    getAssignedTasks(assignedTo: $assignedTo, createdAt: $createdAt) {
      taskId
      data
      typeOfTask
      completed
      createdAt
      oAuthId
      userFirstName
      userLastName
      priority
      title
      assignedTo
      attachedFileIds
      completedAt
      dueDate
      description
      status
      manuallyCreated
      taskTypeCategory
    }
  }
`

export interface IGetAssignedTasksProps {
  assignedTo: String
  createdAt: String
}

export const useGetAssignedTasks = ({ assignedTo, createdAt }: IGetAssignedTasksProps) => {
  return useQuery(GET_ASSIGNED_TASKS, {
    variables: {
      assignedTo,
      createdAt
    }
  })
}
