import React, { useContext } from 'react'
import { MdMoreVert } from 'react-icons/md'
import { useRouter } from 'next/router'
import { useUser } from '@auth0/nextjs-auth0'
import { Avatar, Box, HStack, IconButton, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuList, MenuOptionGroup, Text, Tooltip, useToast, VStack } from '@chakra-ui/react'
import { IUserPharmacy } from '@/utils/types'
import { useEditUser } from '@/hooks/useEditUser'
import { useConfirm } from '@/components/dialogProvider/useConfirm'
import { useTranslation } from '@/utils/i18n'
import { PharmaciesContext } from '@/contexts/PharmacyProvider'
import { useGetMeContext } from '@/contexts'

export const ProfileMenu = () => {
  const { user } = useUser()
  const router = useRouter()
  const { confirm } = useConfirm()
  const t = useTranslation()

  const { me, refetchMe } = useGetMeContext()
  const { changePharmacyId, selectedPharmacy, getPharmacyById, pharamacies } = useContext(PharmaciesContext)

  const toast = useToast()
  const { editUser } = useEditUser()

  const frLangSwitch = router?.locale === 'fr'

  const uniquePharmacies = Array.from(new Set(pharamacies?.map(({ pharmacyId }) => pharmacyId))).map((phId) => pharamacies?.find(({ pharmacyId }) => pharmacyId === phId))

  return (
    <HStack spacing={2}>
      <Tooltip label={selectedPharmacy?.pharmacyName}>
        <Avatar name={selectedPharmacy?.pharmacyName} size="sm" borderWidth={2} borderColor="blue.400"></Avatar>
      </Tooltip>
      <Menu>
        <MenuButton>
          <IconButton aria-label="more" icon={<MdMoreVert />} bg="white" borderWidth={1} color="gray.500" _hover={{ bg: 'gray.100' }} fontSize="2xl" />
        </MenuButton>
        <MenuList boxShadow="lg">
          <Box px={4} py={2}>
            <HStack>
              <Avatar name={user?.name} src={user?.picture} size="sm" />
              <VStack alignItems="start" spacing={0}>
                <Text>{user?.email}</Text>
                <Text fontSize="sm" color="gray">
                  {user?.sub}
                </Text>
              </VStack>
            </HStack>
          </Box>
          <MenuDivider />
          <MenuOptionGroup
            value={`${selectedPharmacy?.pharmacyId}`}
            type="radio"
            title={t('common.Establishments')}
            onChange={async (value) => {
              changePharmacyId(+(value as string))
              await editUser({
                variables: {
                  oAuthId: me?.oAuthId,
                  pharmacyId: +(value as string)
                }
              })

              refetchMe()

              toast({
                title: `${t('common.establishmentSwitchedTo')} ${getPharmacyById(+(value as string)).pharmacyName}`,
                status: 'info',
                position: 'top'
              })
            }}
          >
            {uniquePharmacies?.map((userPharm: IUserPharmacy) => (
              <MenuItemOption value={`${userPharm.pharmacyId}`} key={userPharm.pharmacyId} onClick={() => router.push('/tasks')}>
                <HStack>
                  <Avatar name={userPharm.pharmacyName} size="sm" />{' '}
                  <Text width="200px" textOverflow="..." overflow="hidden" whiteSpace="nowrap">
                    {' '}
                    {userPharm.pharmacyName}({userPharm.pharmacyId})
                  </Text>
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          {/* <MenuGroup title="Profile">
            <MenuItem>My Account</MenuItem>
            <MenuItem>Payments </MenuItem>
          </MenuGroup> */}
          {me?.isSuperAdmin && (
            <>
              <MenuDivider />
              <MenuGroup>
                <MenuItem onClick={() => router.push('/super-admin/users')}>{t('common.superAdmin')}</MenuItem>
              </MenuGroup>
            </>
          )}
          <MenuDivider />
          <MenuGroup title={t('common.help')}>
            <MenuItem>FAQ</MenuItem>
            <MenuItem
              onClick={() => {
                router.push(router.asPath, router.asPath, {
                  locale: frLangSwitch ? 'en' : 'fr'
                })
              }}
            >
              {t('common.switchLanguage')}
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuItem
            onClick={() => {
              confirm({
                title: t('common.areYouSureYouWantToLogout'),
                confirmText: t('common.logOut'),
                onConfirm: () => router.push('/api/auth/logout')
              })
            }}
          >
            {t('common.logOut')}
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}
